.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.my-new-clock{
  
}


.ant-message {
  
  z-index: 9999 !important;
  
}


.sc-launcher {
  
  z-index: 999 !important;
}

.sc-chat-window {
  z-index: 999 !important;
}